// pretty sure we're not using this screen (probably using ~/src/components/templates/Checkout/validateVerificationForm)

import { ButtonV3 as Button, Radio } from '@provi/provi-components'
import { useVerification } from './hooks'
import * as S from './style'

const VerificationForm = () => {
  const { verificationInfo, proceed, selectedMethod, setSelectedMethod, isSendingData } = useVerification()

  const optionsText = {
    email: `Enviar um e-mail para ${verificationInfo.email} com um código`,
    phone: `Enviar um sms para ${verificationInfo.phone} com um código`,
  }

  return (
    <S.WrapperForm>
      <label>
        Para seguir, precisamos realizar uma confirmação. Para isso, selecione como prefere receber o código da verificação:
      </label>
      <S.CheckCardWrapper>
        {verificationInfo.email && (
          <S.CheckCard onClick={() => setSelectedMethod('email')} isActive={selectedMethod === 'email'}>
            <Radio
              text={null}
              name="email"
              value="email"
              onChange={() => setSelectedMethod('email')}
              checked={selectedMethod === 'email'}
            />
            <label>{optionsText.email}</label>
          </S.CheckCard>
        )}

        {verificationInfo.phone && (
          <S.CheckCard onClick={() => setSelectedMethod('phone')} isActive={selectedMethod === 'phone'}>
            <Radio
              text={null}
              name="phone"
              value="phone"
              onChange={() => setSelectedMethod('phone')}
              checked={selectedMethod === 'phone'}
            />
            <label>{optionsText.phone}</label>
          </S.CheckCard>
        )}
      </S.CheckCardWrapper>
      <Button text="Enviar código" type="button" icon onClick={() => proceed()} isLoading={isSendingData} />
    </S.WrapperForm>
  )
}

export default VerificationForm
