import { useMemo } from 'react'
import { SummaryReviewText } from '~/components/atoms'
import { CartPaymentForms, CartTotalPrice } from '~/components/molecules'
import { formatInstallmentText, formatMonthlyInterestText, parsePrice } from '~/utils/index'
import { useCartReview } from './hooks'
import * as S from './style'

interface ICartReview {
  hasBorderTop?: boolean
  shouldUseCartData?: boolean
}

const CartReview = ({ hasBorderTop = true, shouldUseCartData = false }: ICartReview) => {
  const {
    chosenPaymentMethod,
    checkoutSuccessObject,
    invoiceDate,
    courseFinancingCartPaymentData,
    boletoCartPaymentData,
    creditCardCartPaymentData,
    showTaxesOnCheckout,
  } = useCartReview({
    shouldUseCartData,
  })

  const renderCartReview = useMemo(() => {
    switch (chosenPaymentMethod) {
      case 'Boleto parcelado':
        return (
          <S.CartReviewTextContainer>
            <SummaryReviewText title="Entrada" value={parsePrice(courseFinancingCartPaymentData.upfrontAmount / 100)} />
            <SummaryReviewText
              title={formatInstallmentText(courseFinancingCartPaymentData.installmentsToApply)}
              value={parsePrice(courseFinancingCartPaymentData.installmentPrice / 100)}
            />
            <SummaryReviewText
              hidden={!showTaxesOnCheckout}
              title="Taxa de juros"
              value={formatMonthlyInterestText(courseFinancingCartPaymentData.monthlyInterest * 100)}
            />
          </S.CartReviewTextContainer>
        )
      case 'Boleto':
        return (
          <S.CartReviewTextContainer>
            <SummaryReviewText title="Data de vencimento" value={invoiceDate} />
          </S.CartReviewTextContainer>
        )
      case 'Cartão de crédito':
        return (
          <S.CartReviewTextContainer>
            <SummaryReviewText
              title={formatInstallmentText(creditCardCartPaymentData.installmentsToApply)}
              value={parsePrice(creditCardCartPaymentData.installmentPrice)}
            />
            <SummaryReviewText
              title="Taxa de juros"
              value={formatMonthlyInterestText(creditCardCartPaymentData.monthlyInterest)}
            />
          </S.CartReviewTextContainer>
        )
      default:
        return null
    }
  }, [chosenPaymentMethod, invoiceDate, courseFinancingCartPaymentData, creditCardCartPaymentData, showTaxesOnCheckout])

  const renderTotalCartPrice = useMemo(() => {
    switch (chosenPaymentMethod) {
      case 'Boleto parcelado':
        return (
          <div className="flex justify-between w-full text-brand-primary font-bold">
            <span>Total do pedido</span>
            <span> {parsePrice(courseFinancingCartPaymentData.totalAmount / 100)}</span>
          </div>
        )
      case 'Boleto':
        return <CartTotalPrice totalPriceToPay={boletoCartPaymentData.totalAmount} />
      case 'Cartão de crédito':
        return <CartTotalPrice totalPriceToPay={creditCardCartPaymentData.totalAmount} />
      default:
        return null
    }
  }, [
    courseFinancingCartPaymentData?.totalAmount,
    chosenPaymentMethod,
    boletoCartPaymentData?.totalAmount,
    creditCardCartPaymentData?.totalAmount,
  ])

  if (!courseFinancingCartPaymentData && !checkoutSuccessObject) {
    return null
  }

  return (
    <S.CartReviewWrapper hasBorderTop={hasBorderTop}>
      <CartPaymentForms paymentForms={[chosenPaymentMethod]} isVariant={true} />
      {renderCartReview}
      {showTaxesOnCheckout ? renderTotalCartPrice : null}
    </S.CartReviewWrapper>
  )
}

export default CartReview
