import { useContext, useMemo, useRef } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ICourseFinancingInstallment, ICourseFinancingOption } from '~/types/index'
import { formatDate } from '~/utils/formatDate'
import { parsePrice } from '~/utils/parsePrice'
import { useCart } from '../../organisms/Cart/hooks'

interface IUseCourseFinancingPartnerCondition {
  partnerCondition: ICourseFinancingInstallment[] & Partial<ICourseFinancingOption>
  handleSelectedPartnerConditionId: (id: number) => void
}

export const useCourseFinancingPartnerCondition = ({
  partnerCondition,
  handleSelectedPartnerConditionId,
}: IUseCourseFinancingPartnerCondition) => {
  const { setSelectedUpfront, paymentInformationData, selectedPartnerConditionId } = useContext(CheckoutContext)
  const courseFinancingData = paymentInformationData?.response?.CourseFinancing
  const { updateTotalPriceToPay } = useCart()
  const { partnerId } = useContext(CheckoutContext)
  const handleSelected = (partnerConditionId: number) => {
    handleSelectedPartnerConditionId(partnerConditionId)
    setSelectedUpfront(partnerCondition?.upfrontAmountInPercent)
  }

  const installmentSelected = useMemo(
    () => partnerCondition?.installments?.find((installment) => installment.PartnerConditionId === selectedPartnerConditionId),
    [partnerCondition?.installments, selectedPartnerConditionId],
  )

  const useOnChange = (value, callback) => {
    const prevValueRef = useRef()

    if (prevValueRef.current !== value) {
      callback(value)
      prevValueRef.current = value
    }
  }

  const allowedPartnerIds = []

  useOnChange(installmentSelected, (newInstallmentSelected) => {
    if (allowedPartnerIds.includes(partnerId)) {
      if (newInstallmentSelected && newInstallmentSelected.total) {
        updateTotalPriceToPay(newInstallmentSelected.total / 100, true)
      }
    }
  })

  const label = useMemo(() => {
    if (courseFinancingData?.upfrontCanBePaidWithCreditCard) {
      return courseFinancingData?.maxNumberOfInstallments > 1
        ? `Parcele a entrada em até ${courseFinancingData?.maxNumberOfInstallments || 1}x no cartão`
        : 'No cartão de crédito, Pix ou boleto'
    }

    return ''
  }, [courseFinancingData?.upfrontCanBePaidWithCreditCard, courseFinancingData?.maxNumberOfInstallments])

  return {
    formattedUpfrontAmount: parsePrice(
      ((courseFinancingData?.options[0]?.upfrontAmountInCents || 0) +
        (courseFinancingData?.options[0]?.registrationFeeAmountInCents || 0)) /
        100,
      true,
    ),
    upfrontInstalmentDueDate: formatDate(courseFinancingData?.upfrontInstalmentDueDate),
    installmentSelected,
    handleSelected,
    label,
  }
}
