import { useContext, useMemo } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { parseDiscount, parsePrice } from '~/utils/index'
import * as S from './style'

interface ICartTotalPrice {
  discountPercentage?: string | null
  totalPrice?: string | null
  totalPriceToPay?: number | string | null
  hasInstallment?: boolean
}

const CartTotalPrice = ({ discountPercentage, totalPrice, totalPriceToPay, hasInstallment }: ICartTotalPrice) => {
  const { step } = useContext(CheckoutContext)

  const isInSinglePageCheckout = useMemo(
    () => ['singlePageCheckout', 'courses', 'preRegistration', 'loadingFinanceSimulation'].includes(step),
    [step],
  )

  const renderTotalLabel = () => {
    if (isInSinglePageCheckout) {
      return 'Total:'
    }

    if (hasInstallment) {
      return 'Total Parcelado:'
    }

    return 'Subtotal:'
  }

  return (
    <div className="flex flex-row justify-between w-full border-t border-brand-primaryLight pt-3">
      <S.CartTotalPriceLabel isHighlighted={isInSinglePageCheckout}>{renderTotalLabel()}</S.CartTotalPriceLabel>
      <S.CartTotalPriceWrapper>
        {discountPercentage && (
          <S.CartTotalDiscount>
            <S.CartItemDiscountedItem>
              <S.CartTotalItemPrice hasDiscount={true} isHighlighted={isInSinglePageCheckout}>
                {parsePrice(totalPrice || 0)}
              </S.CartTotalItemPrice>
              <S.CartTotalItemPrice isVariant={true} isHighlighted={isInSinglePageCheckout}>
                {parseDiscount(discountPercentage)} OFF
              </S.CartTotalItemPrice>
            </S.CartItemDiscountedItem>
          </S.CartTotalDiscount>
        )}
        <S.CartFullPrice>
          <S.CartTotalPriceValue
            title={`Total do carrinho: ${parsePrice(totalPriceToPay || 0, true)}`}
            isHighlighted={isInSinglePageCheckout}
          >
            {parsePrice(totalPriceToPay || 0)}
          </S.CartTotalPriceValue>
        </S.CartFullPrice>
      </S.CartTotalPriceWrapper>
    </div>
  )
}

export default CartTotalPrice
