import { Chat, WhatsApp } from '@material-ui/icons'
import { ButtonV3 as Button, InputFieldV3 as Input, TextButton, theme } from '@provi/provi-components'
import { ChangeEvent } from 'react'
import OtpInput from 'react-otp-input'
import { IGenerateOTP } from 'types'
import { Alert } from '~/components/atoms/Alert'
import { InputFieldEmail } from '~/components/atoms/InputFieldEmail'
import LoadingInput from '~/components/molecules/LoadingInput'
import { masks } from '~/enums/masks'
import { cn } from '~/utils/cn'
import { addMask, useWindowSize } from '~/utils/index'
import { useValidateVerification } from './hooks'
import * as S from './style'

interface IVerification {
  verificationType: IGenerateOTP['validationType']
}

const ValidateVerificationForm = ({ verificationType }: IVerification) => {
  const {
    verificationInfo,
    otpValues,
    setOtpValues,
    disableFields,
    currentSecond,
    resendEnabled,
    resendOTP,
    resendTime,
    outSideVerificationInfo,
    canChangeIdentification,
    toggleIdentificationScreen,
    isChangingIdentification,
    formikForm,
    isSendingData,
    isLoadingData,
    handleEnterKey,
    isSmsWppOpen,
    setIsSmsWppOpen,
    lastAttemptFeedback,
    restartFlow,
    step,
  } = useValidateVerification({ verificationType })
  const { width: screenWidth } = useWindowSize()
  const { values, setFieldValue, setFieldTouched, validateForm, errors, touched, handleSubmit, isValid } = formikForm
  const isEmail = verificationType === 'email'

  const optionsText = {
    email: `Agora precisamos verificar o seu e-mail. Enviamos um código para ${
      verificationInfo.email || outSideVerificationInfo?.email
    }`,
    phone: `Precisamos verificar o seu celular. Enviamos uma mensagem de texto com o código para ${addMask(
      String(verificationInfo.phone || outSideVerificationInfo?.phone || ''),
      '(##) #####-####',
    )}`,
  }

  const changeIdentificationText = {
    email: `Para alterar o seu e-mail de cadastro, insira o novo endereço abaixo:`,
    phone: `Para alterar o seu celular de cadastro, insira o novo número abaixo:`,
  }

  const handleClick = (flag) => {
    if (isEmail) {
      return resendOTP('email')
    }

    if (!verificationInfo.allowWhatsappVerification || outSideVerificationInfo?.allowWhatsappVerification) {
      return resendOTP('sms')
    }

    setIsSmsWppOpen((old) => !old)

    if (isSmsWppOpen) {
      return resendOTP(flag)
    }
  }

  const generateInformationLabel = () => {
    return (
      <div className="flex flex-col gap-2">
        <span
          className={cn({
            hidden: resendEnabled,
          })}
        >
          <S.Label marginTop={18}>
            Aguarde <b>{currentSecond}s</b> para solicitar um novo código.
          </S.Label>
        </span>

        <div
          className={cn({
            hidden: isSmsWppOpen,
          })}
        >
          <Button
            text="Receber novo código"
            onClick={handleClick}
            width="100%"
            disabled={!resendEnabled}
            isLoading={isSendingData}
          ></Button>
        </div>
        <div
          className={cn({
            'flex flex-col gap-2': true,
            hidden: !isSmsWppOpen,
          })}
        >
          <Button
            text="Receber por SMS"
            width="100%"
            onClick={() => handleClick('sms')}
            color="light"
            buttonProps={{
              startIcon: <Chat />,
            }}
          />
          <Button
            text="Receber por Whatsapp"
            width="100%"
            onClick={() => handleClick('whatsapp')}
            color="light"
            buttonProps={{
              startIcon: <WhatsApp />,
            }}
          />
        </div>
      </div>
    )
  }

  const generateChangeIdentificationLabel = () => {
    if (canChangeIdentification) {
      return (
        <S.Label marginTop={24} blue onClick={toggleIdentificationScreen} tabIndex={0}>
          {isEmail ? 'Mudar meu e-mail' : 'Mudar meu celular'}
        </S.Label>
      )
    } else {
      return null
    }
  }

  const otpStyle = {
    height: 48,
    width: screenWidth && screenWidth > 600 ? 54 : 48,
    borderRadius: 4,
    padding: 12,
    border: `2px solid ${theme.colors.bluePrimaryLight}`,
    marginRight: 8,
    marginTop: 18,
    fontSize: 18,
  }

  if (isChangingIdentification) {
    return (
      <S.WrapperForm>
        <S.Row shouldSpan={true}>
          <S.Row>
            <S.Label>{isEmail ? changeIdentificationText.email : changeIdentificationText.phone} </S.Label> <br />
            {isEmail ? (
              <InputFieldEmail
                label="E-mail"
                placeholder="cecilia@email.com.br"
                emailValue={values.email}
                fieldName="email"
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                hasError={errors.email}
                hasTouched={touched.email}
                validateForm={validateForm}
                handleEnterKey={handleEnterKey}
                mobileWidth="100%"
              />
            ) : (
              <Input
                inputmode="numeric"
                pattern="[0-9]*"
                type="text"
                label="Celular"
                placeholder="(11) 91444-7176"
                name="phone"
                width="100%"
                value={values.phone}
                mask={masks.phone}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('phone', e.target.value)
                  validateForm()
                }}
                onBlur={() => {
                  setFieldTouched('phone')
                  validateForm()
                }}
                isValid={!errors.phone && touched.phone}
                hasError={errors.phone && touched.phone}
                errorMessage={errors.phone}
              />
            )}
          </S.Row>
          <S.ChangeIdentificationButtonsWrapper>
            <Button
              text="Confirmar mudança"
              disabled={!isValid}
              onClick={handleSubmit}
              width="201px"
              mobileWidth="100%"
              isLoading={isSendingData}
              buttonProps={{
                type: 'submit',
              }}
            />
            <TextButton text="Cancelar" onClick={toggleIdentificationScreen} type="reset" />
          </S.ChangeIdentificationButtonsWrapper>
        </S.Row>
      </S.WrapperForm>
    )
  }

  if (isLoadingData && process.env.NODE_ENV !== 'test') {
    return (
      <S.WrapperForm>
        <LoadingInput inputWidth="368px" />
        <br />
        <LoadingInput inputWidth="368px" />
        <br />
        <LoadingInput inputWidth="368px" />
      </S.WrapperForm>
    )
  }

  return resendTime > 0 && window ? (
    <S.WrapperForm>
      <S.Row>
        <S.Label title="Escondemos seus dados para a sua segurança">{isEmail ? optionsText.email : optionsText.phone} </S.Label>
        {generateChangeIdentificationLabel()}
        <S.Label marginTop={24}>Insira o código: </S.Label>
      </S.Row>

      <OtpInput
        isInputNum
        value={otpValues}
        onChange={setOtpValues}
        numInputs={isEmail ? 6 : 4}
        separator={<span></span>}
        inputStyle={otpStyle}
        isDisabled={disableFields}
        data-testid="test-input-otp"
      />
      <S.Row marginTop={32}>{generateInformationLabel()}</S.Row>

      {!!isEmail && (!!verificationInfo?.showRequireHelpButton || !!outSideVerificationInfo?.showRequireHelpButton) && (
        <div className="mt-4">
          <Button
            color="white"
            text="Precisa de ajuda?"
            buttonProps={{
              href: verificationInfo?.requireHelpLink || outSideVerificationInfo?.requireHelpLink,
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
          />
        </div>
      )}

      {step === 'checkoutEmailVerification' &&
        (!!verificationInfo?.allowRestartFlow || !!outSideVerificationInfo?.allowRestartFlow) && (
          <div className="mt-4">
            <Button color="white" text="Não tenho acesso ao e-mail" onClick={restartFlow} />
          </div>
        )}

      {lastAttemptFeedback && (
        <Alert status="warning" className="mt-4">
          {lastAttemptFeedback}
        </Alert>
      )}
    </S.WrapperForm>
  ) : (
    <> </>
  )
}

export default ValidateVerificationForm
