import { useRouter } from 'next/router'
import { memo, useMemo } from 'react'
import { ICartData, IItem, IRemoveFromCart } from 'types'
import { AddButton } from '~/components/atoms'
import { CartItem, CartTotalPrice } from '~/components/molecules'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import { useCartItems } from './hooks'
import * as S from './style'

interface ICartItems {
  cartData: ICartData
  isCartLoading?: boolean
  removeFromCart: ({ classId }: IRemoveFromCart) => void
}

const CartItems = ({ cartData, removeFromCart, isCartLoading }: ICartItems) => {
  const router = useRouter()
  const { partnerSlug, route, handleRemoveFromCart, step, isInCourseFinancingReviewScreen } = useCartItems({
    removeFromCart,
  })

  const renderItems = useMemo(() => {
    return cartData?.courses?.map((item: IItem) => {
      return (
        <CartItem
          key={item.CourseClassId}
          item={item}
          handleRemoveFromCart={handleRemoveFromCart}
          isReadOnly={step === 'checkoutSuccess'}
        />
      )
    })
  }, [cartData, handleRemoveFromCart, step])

  const renderTotalPrice = useMemo(() => {
    return (
      <CartTotalPrice
        discountPercentage={cartData?.discountPercentage}
        totalPrice={cartData?.totalPrice}
        totalPriceToPay={cartData?.totalPriceToPay}
        hasInstallment={cartData?.hasInstallment}
      />
    )
  }, [cartData?.discountPercentage, cartData?.totalPrice, cartData?.totalPriceToPay, cartData?.hasInstallment])

  const renderAddToCart = useMemo(() => {
    if (route === '/checkout/[slug]' && !isInCourseFinancingReviewScreen && step !== 'checkoutSuccess') {
      return (
        <>
          {cartData?.courseClassIds?.length === 0 && (
            <>
              <br />
              <br />
            </>
          )}
          <S.CartAddToCartButtonWrapper href={changeRouteKeepParams(router.asPath, `/courses/${partnerSlug}`)} passHref>
            <S.AddToCartButton>
              Adicionar cursos <AddButton />
            </S.AddToCartButton>
          </S.CartAddToCartButtonWrapper>
        </>
      )
    }
  }, [route, router.asPath, partnerSlug, isInCourseFinancingReviewScreen, step, cartData])

  const renderCartItems = useMemo(() => {
    return cartData?.courses?.length ? (
      <S.CartItems>
        {renderItems}
        {cartData?.canAddCourses && renderAddToCart}
        {renderTotalPrice}
      </S.CartItems>
    ) : (
      <S.EmptyMessage>
        Seu carrinho está vazio
        {cartData?.canAddCourses && renderAddToCart}
      </S.EmptyMessage>
    )
  }, [cartData, renderItems, renderTotalPrice, renderAddToCart])

  const renderLoadingCartItems = useMemo(() => {
    return (
      <S.LoadingCartItem>
        <S.LoadingCartItemName />
        <S.LoadingCartItemCourseName />
        <S.LoadingCartItemPrice />
      </S.LoadingCartItem>
    )
  }, [])

  return (
    <S.CartItemsWrapper hasItems={!!cartData?.courses?.length} isSuccess={step === 'checkoutSuccess'}>
      {isCartLoading ? renderLoadingCartItems : renderCartItems}
    </S.CartItemsWrapper>
  )
}

export default memo(CartItems)
