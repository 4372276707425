import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const CartPaymentFormTitle = styled.p`
  font-weight: bold;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.0075em;
  color: ${theme.colors.greyPrimary};
`

export const PaymentForm = styled.span`
  display: flex;
  align-items: center;
  gap: 9px;
`

export const PaymentFormName = styled.p<{ isVariant?: boolean }>`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;

  ${({ isVariant }) =>
    isVariant &&
    css`
      font-weight: 700;
    `}
`
