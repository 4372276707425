import { Check } from '@material-ui/icons'
import { Radio } from '@provi/provi-components'
import { useCallback, useMemo } from 'react'
import { ICourseFinancingInstallment, ICourseFinancingOption } from 'types'
import { useShowTaxesOnCheckout } from '~/hooks/useShowTaxesOnCheckout'
import { cn } from '~/utils/cn'
import { formatMonthlyInterestText, parsePrice } from '~/utils/index'
import { DisclosureComponent } from '../Disclosure'
import { useCourseFinancingPartnerCondition } from './hooks'
import * as S from './style'

interface ICourseFinancingPartnerCondition {
  partnerCondition: ICourseFinancingInstallment[] & Partial<ICourseFinancingOption>
  selectedPartnerConditionId: number | null
  handleSelectedPartnerConditionId: (id: number) => void
}

const CourseFinancingPartnerCondition = ({
  partnerCondition,
  selectedPartnerConditionId,
  handleSelectedPartnerConditionId,
}: ICourseFinancingPartnerCondition) => {
  const { showTaxesOnCheckout } = useShowTaxesOnCheckout()
  const { handleSelected, formattedUpfrontAmount, upfrontInstalmentDueDate, installmentSelected, label } =
    useCourseFinancingPartnerCondition({
      partnerCondition,
      handleSelectedPartnerConditionId,
    })

  const installmentBox = useCallback(
    ({ PartnerConditionId, installmentsToApply, monthlyInterest, pmt }: ICourseFinancingInstallment) => {
      const isInstallmentActive = selectedPartnerConditionId === PartnerConditionId

      return (
        <S.CourseFinancingPartnerConditionWrapper key={PartnerConditionId} onClick={() => handleSelected(PartnerConditionId)}>
          <S.PartnerConditionInstallmentContainer>
            <S.PartnerConditionLabel as="span">
              <Radio
                text={''}
                onChange={() => handleSelected(PartnerConditionId)}
                checked={isInstallmentActive}
                variant={'secondary'}
                name={`partner-condition-${selectedPartnerConditionId}`}
              />
              <span>
                {installmentsToApply}x {parsePrice(pmt / 100)} <div className="mr-1" />
              </span>

              {showTaxesOnCheckout ? `(Juros ${formatMonthlyInterestText(monthlyInterest * 100)})` : ''}
            </S.PartnerConditionLabel>
          </S.PartnerConditionInstallmentContainer>
        </S.CourseFinancingPartnerConditionWrapper>
      )
    },
    [handleSelected, selectedPartnerConditionId, showTaxesOnCheckout],
  )

  const valueUpfrontBox = useMemo(() => {
    return (
      <div className="mt-2">
        <span className="block text-base">{formattedUpfrontAmount}</span>
        <span
          className={cn({
            'flex flex-row gap-0.5 text-xs bg-teal-600 rounded-full px-2 py-0.5 text-white mt-2 mb-1': true,
            hidden: !label,
          })}
        >
          <Check className="text-white max-h-4 max-w-4" />
          {label}
        </span>
        <span className="text-xs">Vencimento dia {upfrontInstalmentDueDate}</span>
      </div>
    )
  }, [formattedUpfrontAmount, upfrontInstalmentDueDate, label])

  return (
    <div className={`w-full border-2 border-solid border-brand-primaryLight rounded-lg sm:w-[464px]`}>
      <DisclosureComponent isOpen={true} title="Entrada" value={valueUpfrontBox} paddingVariant />
      <hr className={`border-0 border-b-2 border-solid border-brand-primaryLight`} />

      <DisclosureComponent
        isOpen={true}
        title="Parcelas"
        value={'Agora, selecione em quantas parcelas você deseja pagar o valor restante:'}
      >
        {partnerCondition?.installments?.map((installment) => installmentBox(installment))}
      </DisclosureComponent>

      <div
        className={
          showTaxesOnCheckout
            ? 'h-[59px] flex items-center bg-[${theme.colors.brandSecundaryLight}] px-6 overflow-hidden'
            : 'hidden'
        }
      >
        <S.PartnerConditionPrice>
          Total a pagar:{' '}
          {installmentSelected ? (
            <>
              {parsePrice(installmentSelected?.total / 100)}
              <small className={`ml-1 text-sm align-top text-brand-primary`}>&#42;</small>
            </>
          ) : (
            'R$ -'
          )}
        </S.PartnerConditionPrice>
      </div>
    </div>
  )
}

export default CourseFinancingPartnerCondition
