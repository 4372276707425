import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { Caption } from '~/styles/global'

interface ICartItemPrice {
  hasDiscount?: boolean
  isVariant?: boolean
  isHighlighted?: boolean
}

export const CartTotalPriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 2px;
`

export const CartTotalPriceLabel = styled(Caption)<{ isHighlighted?: boolean }>`
  font-weight: 400;
  color: ${theme.colors.greyNeutral};

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: ${theme.colors.black100};
    `};
`

export const CartTotalPriceValue = styled.span<{ isHighlighted: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0075em;
  color: ${theme.colors.greyNeutral};

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    letter-spacing: 0.005em;
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      font-weight: 700;
      color: ${theme.colors.bluePrimary};
      font-size: 16px;
      line-height: 24px;
    `};
`

export const CartTotalDiscount = styled.span`
  display: flex;
  flex-direction: column;
`

export const CartItemDiscountedItem = styled.span`
  display: flex;
  gap: 4px;
`

export const CartTotalItemPrice = styled.span<ICartItemPrice>`
  font-weight: ${({ isVariant }) => (isVariant ? 700 : 400)};
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${({ isVariant }) => (isVariant ? theme.colors.bluePrimary : theme.colors.greyNeutral)};

  ${({ hasDiscount }) =>
    hasDiscount &&
    css`
      color: ${theme.colors.greyNeutral};
      text-decoration-line: line-through;
    `};

  ${theme.breakpoints.down('md')} {
    line-height: 16px;
    letter-spacing: 0.0075em;
  }

  ${({ isHighlighted, isVariant }) =>
    isHighlighted &&
    css`
      font-weight: ${isVariant ? 700 : 400};
    `};
`

export const CartFullPrice = styled.span`
  display: flex;
  gap: 4px;
`
