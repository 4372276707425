import { PaymentFormType } from '~/components/atoms'
import { useCart } from '~/components/organisms/Cart/hooks'
import { translatePaymentForm } from '~/utils/index'
import LoadingInput from '../LoadingInput'
import * as S from './style'

interface ICartPaymentForms {
  paymentForms?: string[]
  isVariant?: boolean
}

const CartPaymentForms = ({ paymentForms, isVariant }: ICartPaymentForms) => {
  const { isCartLoading } = useCart()
  if (!paymentForms?.length && isCartLoading) {
    return <LoadingInput hasLabel={false} />
  }

  if (!paymentForms?.length) {
    return null
  }

  return (
    <S.Wrapper>
      {!isVariant && <S.CartPaymentFormTitle>Formas de pagamento</S.CartPaymentFormTitle>}
      {/* todo: from screen 6 and above show only the selected paymentCondition */}
      {paymentForms?.map((form) => (
        <S.PaymentForm key={form.toString()}>
          <PaymentFormType type={form} />
          <S.PaymentFormName isVariant={isVariant}>{translatePaymentForm(form)}</S.PaymentFormName>
        </S.PaymentForm>
      ))}
    </S.Wrapper>
  )
}

export default CartPaymentForms
