import { theme } from '@provi/provi-components'
import NextLink from 'next/link'
import styled, { css, keyframes } from 'styled-components'

interface ICartItemsWrapper {
  hasItems: boolean
  isSuccess: boolean
}

export const CartItemsWrapper = styled.div<ICartItemsWrapper>`
  margin-bottom: 5px;
  padding-bottom: ${({ hasItems }) => (hasItems ? '26px' : '20px')};
  padding-top: 22px;
  cursor: default;
`

export const EmptyMessage = styled.div`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${theme.colors.greyPrimary};
`

export const CartItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 21px;
`

export const CartAddToCartButtonWrapper = styled(NextLink)``

export const AddToCartButton = styled.a`
  width: fit-content;

  cursor: pointer;
  color: ${theme.colors.bluePrimary};
  outline-offset: 4px;
  font-family: 'Montserrat', sans serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover,
  &:focus {
    color: ${theme.colors.blueSecundary};

    path {
      fill: ${theme.colors.blueSecundary};
    }
  }

  &:active {
    color: ${theme.colors.bluePrimaryDark};

    path {
      fill: ${theme.colors.bluePrimaryDark};
    }
  }
`

const loadingAnimation = keyframes`
    0%{background-position: 0% 0%}
    50%{background-position: 100% 0%}
    100%{background-position: 0% 0%}
`

export const LoadingCartItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  * {
    background: ${css`linear-gradient(270deg, ${theme.colors.bluePrimaryLight}, ${theme.colors.white100})`};
    background-size: 200%;

    -webkit-animation: ${loadingAnimation} 1s ease infinite;
    -moz-animation: ${loadingAnimation} 1s ease infinite;
    animation: ${loadingAnimation} 1s ease infinite;
    animation: ${loadingAnimation} 1s ease infinite;
  }
`

export const LoadingCartItemName = styled.div`
  width: 100%;
  height: 24px;
  border-radius: 4px;
`

export const LoadingCartItemCourseName = styled.div`
  width: 100%;
  height: 24px;
  border-radius: 4px;
`

export const LoadingCartItemPrice = styled.div`
  display: flex;
  align-self: flex-end;
  width: 40%;
  height: 24px;
  border-radius: 4px;
`
