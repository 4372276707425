import { useFormik } from 'formik'
import { KeyboardEvent, useCallback, useContext, useEffect, useState } from 'react'
import useCountDown from 'react-countdown-hook'
import { toast } from 'react-toastify'
import { IGenerateOTP, IVerificationInformations } from 'types'
import * as Yup from 'yup'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { generateOTP, getOTPInformations, restartFlow, validateOTP } from '~/services/api'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import { isEmailValid, isPhoneValid } from '~/utils/validation'

interface IVerification {
  verificationType: IGenerateOTP['validationType']
}

export const useValidateVerification = ({ verificationType }: IVerification) => {
  const {
    step,
    verificationInfo: outSideVerificationInfo,
    setExistingRequestsData,
    isSendingData,
    isLoadingData,
    setIsRedirecting,
    setAlreadyHasActiveProductType,
  } = useContext(CheckoutContext)
  const [disableFields, setDisableFields] = useState<boolean>(false)
  const [otpValues, setOtpValues] = useState<string>('')
  const [currentSecond, setCurrentSecond] = useState<number>(0)
  const [resendEnabled, setResendEnabled] = useState<boolean>(false)
  const [verificationInfo, setVerificationInfo] = useState<IVerificationInformations>({})
  const [isChangingIdentification, setIsChangingIdentification] = useState(false)
  const interval = 1000
  const [resendTime, setResendTime] = useState<number>(0)
  const [isSmsWppOpen, setIsSmsWppOpen] = useState<boolean>(false)
  const [lastAttemptFeedback, setLastAttemptFeedback] = useState<string | null>(null)
  const [timeLeft, { start }] = useCountDown(resendTime, interval)
  const { route, router, productType } = useContext(ParamsContext)

  const isEmail = verificationType === 'email'
  const canChangeIdentification = step !== 'checkoutEmailVerification'

  useEffect(() => {
    if ((isEmail && otpValues.length === 6) || (verificationType === 'phone' && otpValues.length === 4)) {
      setDisableFields(true)
      proceed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpValues])

  useEffect(() => {
    const timeLeftInSeconds = timeLeft / 1000
    setCurrentSecond(timeLeftInSeconds)
    if (timeLeftInSeconds === 0) {
      setResendEnabled(true)
    } else {
      setResendEnabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, resendEnabled])

  useEffect(() => {
    if (resendTime > 0) start(resendTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendTime])

  const startScreen = useCallback(
    async (screen?: string) => {
      const screenInformations = await getOTPInformations(screen ? screen : step)
      if (typeof screenInformations !== 'string') {
        if (screenInformations.response) {
          setVerificationInfo(screenInformations.response)
          setResendTime(screenInformations.response.emailResendTime || screenInformations.response.smsResendTime || 0)
        }
      }
    },
    [step, setVerificationInfo, setResendTime],
  )

  useEffect(() => {
    if (step !== 'checkoutEmailVerification') {
      startScreen()
    } else {
      setResendTime(30000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const proceed = useCallback(async () => {
    const response = await validateOTP({
      validationType: verificationType,
      currentScreen: step,
      code: otpValues,
    })

    if (typeof response !== 'string') {
      response?.token && sessionStorage.setItem('user_token', response.token)
      setAlreadyHasActiveProductType(response?.alreadyHasActiveProductType)
      setExistingRequestsData(response.existingRequests)

      setDisableFields(false)
      setOtpValues('')

      if (productType === 'ProviPay' && route !== '/checkout/[slug]' && response?.partnerSlug) {
        setIsRedirecting(true)
        await router.push(changeRouteKeepParams(router.asPath, `/checkout/${response.partnerSlug}`))
      }

      if (response.nextScreen === 'emailVerification') {
        startScreen(response.nextScreen)
        start(30000)
      }

      return
    }

    setDisableFields(false)
    setOtpValues('')
  }, [
    route,
    router,
    otpValues,
    verificationType,
    step,
    setDisableFields,
    setOtpValues,
    start,
    startScreen,
    setExistingRequestsData,
    setIsRedirecting,
    productType,
    setAlreadyHasActiveProductType,
  ])

  const resendOTP = async (provider: 'email' | 'sms' | 'whatsapp') => {
    const response = await generateOTP({
      validationType: verificationType,
      ...(provider === 'whatsapp' && { provider }),
    })

    if (typeof response !== 'string') {
      toast.success('Reenviamos o código', {
        toastId: 'otp-resent',
      })
      start(resendTime)

      if (response?.lastAttemptFeedback) {
        setLastAttemptFeedback(response?.lastAttemptFeedback)
      }
    } else {
      toast.error('Ocorreu um erro ao reenviar o código de verificação', {
        toastId: 'otp-resent-error',
      })
    }
  }

  const toggleIdentificationScreen = useCallback(() => {
    setIsChangingIdentification((oldValue) => !oldValue)
  }, [setIsChangingIdentification])

  const handleChangeIdentification = useCallback(
    async (e) => {
      formikForm.validateForm()

      const formValues = Object.values(e)

      const newIdentification = formValues.find((value) => value !== '') as string

      if (newIdentification) {
        const response = await validateOTP({
          validationType: verificationType,
          currentScreen: step,
          newIdentification: newIdentification,
        })

        // this rerender the form data
        if (typeof response !== 'string') {
          startScreen()
          setIsChangingIdentification(false)
          formikForm.resetForm()
          toast.success(`Alteramos o seu ${isEmail ? 'email' : ' número de celular'}`)

          return
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, verificationType],
  )

  const formikForm = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      phone: '',
      email: '',
    },
    validationSchema: Yup.object({
      phone: isEmail ? Yup.string().notRequired() : Yup.string().required(requiredErrorMessages.phone),
      email: isEmail ? Yup.string().required(requiredErrorMessages.emailInvalid) : Yup.string().notRequired(),
    }),
    onSubmit: handleChangeIdentification,

    validate: (fields) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (isEmail && cur === 'email' && fields[cur] && !isEmailValid(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.emailInvalid,
          }
        }

        if (!isEmail && cur === 'phone' && fields[cur] && !isPhoneValid(fields[cur])) {
          if (fields[cur].length === 1) {
            return {
              ...prev,
              [cur]: requiredErrorMessages.phone,
            }
          }
          return {
            ...prev,
            [cur]: errorMessages.phone,
          }
        }

        return { ...prev }
      }, {})
    },
  })

  const handleEnterKey = (event: KeyboardEvent<HTMLFormElement>) => {
    const { isSubmitting, handleSubmit } = formikForm

    if (event.key === 'Enter') {
      !isSubmitting && handleSubmit(event)
    }
  }

  return {
    verificationInfo,
    outSideVerificationInfo,
    proceed,
    otpValues,
    setOtpValues,
    disableFields,
    setDisableFields,
    currentSecond,
    resendEnabled,
    resendOTP,
    resendTime,
    canChangeIdentification,
    toggleIdentificationScreen,
    isChangingIdentification,
    step,
    formikForm,
    isSendingData,
    isLoadingData,
    handleEnterKey,
    isSmsWppOpen,
    setIsSmsWppOpen,
    lastAttemptFeedback,
    restartFlow,
  }
}
